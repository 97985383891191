<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <v-dialog v-model="pdfDialogVisible" persistent max-width="900px">
    <v-card style="position: relative">
      <v-card-title>
        <div class="close-and-flags">
          <div class="flag-switcher flag-button2">
            <v-btn icon @click="toggleLanguage('es')" class="flag-button">
              <v-img
                :class="{ 'rotate-flag': selectedFlag === 'es' }"
                src="@/assets/Spain.png"
                alt="Spanish"
                class="flag-icon"
              ></v-img>
            </v-btn>
            <v-btn icon @click="toggleLanguage('en')">
              <v-img
                :class="{ 'rotate-flag': selectedFlag === 'en' }"
                src="@/assets/Uk.png"
                alt="English"
                class="flag-icon"
              ></v-img>
            </v-btn>
          </div>
          <v-btn
            icon
            @click="pdfDialogVisible = false"
            class="close-btn"
            color="#01C3C0"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <div class="width-100 d-flex justify-center align-center">
        <h3 style="text-decoration: underline">
          {{ TypesOfReports }}
        </h3>
      </div>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4 v-html="ItsPossibleToDownload"></h4>

              <v-checkbox
                color="#01C3C0"
                :label="`${FitReport}`"
                v-model="opcionesReportDialog[0]"
              >
              </v-checkbox>
              <v-list-item-subtitle class="mt-n7" v-html="FitLeyend">
              </v-list-item-subtitle>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                color="#01C3C0"
                :label="`${EthicalReport}`"
                v-model="opcionesReportDialog[1]"
              ></v-checkbox>
              <v-list-item-subtitle class="mt-n7">
                {{ EthicalLeyend }}
              </v-list-item-subtitle>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                color="#01C3C0"
                :label="`${LeadershipReport}`"
                v-model="opcionesReportDialog[2]"
              ></v-checkbox>
              <v-list-item-subtitle class="mt-n7">
                {{ LeadershipLeyend }}
              </v-list-item-subtitle>
            </v-col>
            <v-col cols="12">
              <!-- Nueva opción -->
              <v-checkbox
                color="#01C3C0"
                :label="`${MindsetFitReport}`"
                v-model="opcionesReportDialog[3]"
              ></v-checkbox>
              <v-list-item-subtitle class="mt-n7">
                {{ MindsetFitLeyend }}
              </v-list-item-subtitle>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          class="pdf-button"
          width="100%"
          raised
          color="#01C3C0"
          @click="confirmPdfCreation"
          >Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-sheet :height="table_top_sheet_height" width="100%" color="transparent">
    <!--Top Row-->
    <v-row dense class="mx-5" style="height: 100%">
      <!--Table Title-->
      <v-col cols="6" md="3" class="d-flex align-center">
        <div>
          <h3 class="text-uppercase" style="color: #34495e">
            {{ table_title }}
          </h3>
        </div>
      </v-col>
      <v-col cols="6" md="2" class="d-flex justify-end align-center">
        <v-btn
          class="btnGest"
          @click="parametrization_view_change_step_to_desktop(4)"
          >Gestionar Parametrizaciones</v-btn
        >
      </v-col>

      <!--Tags Buttons-->
      <v-col cols="6" md="2" class="d-flex justify-end align-center">
        <TagsMenu />
      </v-col>

      <!--Filter Text Field-->
      <v-col cols="12" md="4" class="d-flex justify-end align-center">
        <v-text-field
          v-model="search"
          density="compact"
          variant="flat"
          :placeholder="search_placeholder"
          bg-color="white"
          hide-details
          class="search_tf mx-1"
        >
          <template v-slot:append-inner>
            <div
              style="height: 100%; position: absolute"
              class="filter_icon rounded d-flex align-center mt-n2 px-2 ml-n6"
            >
              <v-icon size="25" color="white" location="top right" class="  "
                >mdi mdi-magnify</v-icon
              >
            </div>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-sheet>
  <!--Table-->
  <v-sheet :height="table_sheet_height" width="100%" color="transparent">
    <v-row dense class="mx-4">
      <v-col cols="12" class="text-center">
        <v-data-table
          class="headers bg-transparent overflow-x-hidden"
          :headers="get_headers"
          fixed-header
          :items="searchCandidate"
          :page="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          density="compact"
          :height="table_sheet_height"
        >
          <template v-slot:bottom>
            <v-row wrap no-gutters class="d-flex justify-end pt-3">
              <v-col cols="7" class="">
                <div class="d-flex flex-row align-center h-100">
                  <h3 class="mr-2" style="color: #34495e">{{ total }}</h3>
                  <div class="cand_total">
                    <h3 style="color: #00c3c0">{{ candidates_count }}</h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="2" class="">
                <v-select
                  class="mx-4"
                  v-model="items_per_page_select"
                  variant="outlined"
                  hide-details
                  density="compact"
                  :label="item_per_page_placeholder"
                  @update:modelValue="
                    itemsPerPage = items_per_page_select.value
                  "
                  :items="get_per_page_choices"
                  item-title="text"
                  item-value="value"
                  return-object
                  color="#00c3c0"
                  style="color: #34495e !important"
                >
                </v-select>
              </v-col>
              <v-col cols="3" class="">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  density="compact"
                  total-visible="5"
                  style="color: #34495e"
                ></v-pagination>
              </v-col>
            </v-row>
          </template>

          <!--eslint-disable-next-line vue/valid-v-slot-->
          <template v-slot:column.mindset_fit="{ column }">
            {{ column.title }}
            <v-tooltip activator="parent" location="top">
              {{ legend1 }}
            </v-tooltip>
          </template>

          <!--eslint-disable-next-line vue/valid-v-slot-->
          <template v-slot:item.parametrizations="{ item }">
            <div class="dropdown">
              <v-chip
                pill
                link
                size="small"
                :class="['dropbtn', { inverted: shouldInvertColors(item) }]"
                @click="openDropdownDialog(item.raw.token)"
              >
                <span class="centerSpan">
                  {{
                    getParamName(item.raw.selectedParametrization) ||
                    "Sin Parametrización asignada"
                  }}
                </span>
                <v-icon
                  v-if="
                    item.raw.selectedParametrization !== null &&
                    item.raw.selectedParametrization !== 'N/D'
                  "
                  @click.stop="selectParametrization(item.raw.token, null)"
                  class="close-icon2"
                  >mdi-close-circle-outline</v-icon
                >
                <v-icon v-else class="menu-icon">mdi-menu-down</v-icon>
              </v-chip>
              <v-dialog
                v-model="dropdownVisible[item.raw.token]"
                max-width="490"
              >
                <v-card>
                  <v-list class="scrollable-list">
                    <h2 class="ml-6">¿Qué parametrización deseas asignar?</h2>
                    <v-list-item
                      v-for="param in totalParametization"
                      :key="param.totalParam_id"
                      class="item-list-param"
                      @click="
                        openConfirmationDialog(
                          item.raw.token,
                          param.totalParam_id
                        );
                        closeDropdownDialog(item.raw.token);
                      "
                    >
                      <v-icon class="mr-2">mdi-pin</v-icon>
                      {{
                        param.totalParam_name || "Sin Parametrización asignada"
                      }}
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-dialog>
            </div>
          </template>

          <!--eslint-disable-next-line vue/valid-v-slot-->
          <template v-slot:column.desired_org_fit="{ column }">
            {{ column.title }}
            <v-tooltip activator="parent" location="top">
              {{ legend2 }}
            </v-tooltip>
          </template>
          <!--eslint-disable-next-line vue/valid-v-slot-->
          <template v-slot:item.fechaRegistro="{ item }">
            {{ item.value.fechaRegistro }}
          </template>

          <!--eslint-disable-next-line vue/valid-v-slot-->
          <template v-slot:item.email_user="{ item }">
            <a
              class="text-decoration-none"
              href="#"
              @click="candidate_details(item.raw)"
              style="font-size: 12px; color: #585858"
            >
              {{ item.value.email_user }}
            </a>
          </template>

          <!--eslint-disable-next-line vue/valid-v-slot-->
          <template v-slot:item.name="{ item }">
            <a
              class="text-decoration-none"
              href="#"
              @click="candidate_details(item.raw)"
              style="font-size: 12px; color: #585858"
            >
              {{ item.value.name }}
            </a>
          </template>

          <!--eslint-disable-next-line vue/valid-v-slot-->
          <template v-slot:item.tags="{ item }">
            <div v-if="item.raw.tags.length === 0">
              <v-menu location="bottom">
                <template v-slot:activator="{ props }">
                  <v-chip
                    pill
                    v-bind="props"
                    link
                    size="small"
                    class="select_label_chip"
                    append-icon="mdi mdi-menu-down"
                  >
                    {{ tags_menu }}
                  </v-chip>
                </template>
                <v-card
                  elevation="2"
                  max-height="250"
                  class="overflow-auto scroll"
                >
                  <v-list density="compact">
                    <template v-for="tag in tags_list" :key="tag">
                      <v-list-item
                        class=""
                        density="compact"
                        :value="tag"
                        active-color="#00c3c0"
                        rounded="lg"
                        @click="setTagToCandidate(item.raw, tag)"
                      >
                        <v-list-item-title> {{ tag.texto }} </v-list-item-title>
                        <template v-slot:append>
                          <v-icon color="black">mdi mdi-plus</v-icon>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>
              </v-menu>
            </div>

            <div v-else class="d-flex flex-row justify-center">
              <div v-if="item.raw.tags.length === 1" class="d-flex flex-row">
                <template
                  v-for="tag in tags_to_display(item.raw.tags, 1)"
                  :key="tag"
                >
                  <v-chip
                    pill
                    closable
                    size="small"
                    class="chip mr-1"
                    close-icon="mdi mdi-close-circle-outline"
                    @click:close="deleteCandidateTag(item.raw, tag)"
                  >
                    {{ tag.texto }}
                    <v-tooltip activator="parent" location="top">{{
                      tag.texto
                    }}</v-tooltip>
                  </v-chip>
                </template>
              </div>
              <div v-else class="d-flex flex-row">
                <template
                  v-for="tag in tags_to_display(item.raw.tags, 2)"
                  :key="tag"
                >
                  <v-chip
                    pills
                    closable
                    size="small"
                    class="chip mr-1"
                    close-icon="mdi mdi-close-circle-outline"
                    @click:close="deleteCandidateTag(item.raw, tag)"
                  >
                    {{ tag_text_to_display(tag.texto) }}
                    <v-tooltip activator="parent" location="top">{{
                      tag.texto
                    }}</v-tooltip>
                  </v-chip>
                </template>
              </div>
              <v-chip pill link size="small" class="select_label_chip">
                <v-icon>mdi mdi-dots-horizontal</v-icon>
                <CandidateTagsDialog :current_candidate="item.raw" />
              </v-chip>
            </div>
          </template>

          <!--eslint-disable-next-line vue/valid-v-slot-->
          <template v-slot:item.actions="{ item }">
            <v-btn
              variant="text"
              density="compact"
              size="small"
              icon="mdi mdi-file-download"
              class="table_actions_icons"
              @click="openPdfDialog(item.raw)"
            >
            </v-btn>
            <v-btn
              variant="text"
              density="compact"
              size="small"
              icon="mdi mdi-eye"
              class="table_actions_icons ml-2"
              @click="candidate_details(item.raw)"
            >
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <template>
      <v-dialog
        v-model="confirmationDialogVisible"
        persistent
        max-width="400px"
      >
        <v-card>
          <v-card-title class="headline">Confirmar acción</v-card-title>
          <v-card-text>
            ¿Estás seguro de que deseas seleccionar esta parametrización?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="confirmSelection">
              Confirmar
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="confirmationDialogVisible = false"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-sheet>

  <CandidateReport ref="candidateReport" :language="selectedLanguage" />
</template>

<script setup>
import { VDataTable } from "vuetify/labs/VDataTable";
</script>
<script>
import { mapState, mapMutations } from "vuex";

//API Services
import DictionaryForReport from "@/globals/DictionaryForReport";
import ProfileEvaluationService from "@/services/ProfileEvaluationService";
import TagsService from "@/services/TagsService";

import CandidateReport from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReport.vue";
import TagsMenu from "@/components/DashboardComponents/ProfilesEvaluation/TagsMenu.vue";
import CandidateTagsDialog from "./CandidateTagsDialog.vue";

export default {
  name: "CandidatesDesktopTable",
  props: {
    table_top_sheet_height: {
      type: Number,
    },
    table_sheet_height: {
      type: Number,
    },
    candidates_count: {
      type: Number,
    },
  },
  components: {
    TagsMenu,
    CandidateTagsDialog,
    CandidateReport,
  },
  data() {
    return {
      confirmationDialogVisible: false,
      selectedCandidateToken: null,
      selectedParamId: null,
      /* parametrizationNames: [], */
      selectedParametrizations: [],
      showAlert: false,

      dropdownVisible: {},

      language: "es",
      selectedLanguage: "es",
      selectedFlag: "es",
      TypesOfReports: "Tipos de informe de candidato",
      ItsPossibleToDownload:
        "Es posible descargar el<strong class='ment-inf'> informe de mentalidad</strong> y los siguientes informes adicionales:",
      FitReport: "Informe de Ajuste con cultura corporativa",
      EthicalReport: "Informe de Comportamiento Ético",
      LeadershipReport: "Informe de Liderazgo",
      MindsetFitReport: "Informe de MindsetFit",
      FitLeyend:
        "Opción que compara la cultura corporativa con el candidato<strong> (Es necesario haber diagnosticado la cultura corporativa)</strong>",
      EthicalLeyend:
        "Opción que incluye el índice de comportamiento ético del candidato",
      LeadershipLeyend:
        "Opción que incluye el índice de liderazgo del candidato",
      MindsetFitLeyend: "Opción que incluye MindsetFit del candidato",
      opcionesReportDialog: [true, true, true, true],
      pdfDialogVisible: false,

      page: 1,
      itemsPerPage: 10,

      items_per_page_select: { text: "10", value: 10 },

      item_per_page_placeholder: "",

      //Tags Menu
      tags_menu: "",

      //Table Title
      table_title: "",

      //Text Field Search Placeholder
      search_placeholder: "",
      search: "",

      //Table Headers
      fechaRegistro: "",
      email_user: "",
      full_name: "",
      mindset_fit: "",
      desired_org_fit: "",
      dominant_individual_arq: "",
      parameter_name: "",
      desired_org_culture: "",
      tags: "",
      actions: "",

      total: "",
      all_choice: "",

      legend1: "",
      legend2: "",
      paraIDealMent: "",
    };
  },

  computed: {
    ...mapState([
      "candidates",
      "tags_list",
      "search",
      "user_info",
      "candidates_to_show",
      "allowDownloadReport",
      "detailed_view_step",
      "totalParametization",
    ]),
    parametrizationNames() {
      return this.candidates_to_show.map((candidate) => ({
        id: candidate.parameter_id,
        name: candidate.parameter_name,
        nameWithId: `${candidate.parameter_name} (ID: ${candidate.parameter_id})`,
      }));
    },
    parametrizationCandidates() {
      return this.candidates_to_show.map((candidate) => ({
        ...candidate,
        selectedParametrization: null,
        showAlert: false,
      }));
    },

    pageCount() {
      let c = this.countCandidate();
      const pageCount = c / this.itemsPerPage;
      return Math.ceil(pageCount);
    },

    searchCandidate() {
      //const search = this.search.toLowerCase()

      if (!this.search) {
        return this.candidates_to_show;
      } else {
        let array = this.candidates_to_show.filter((i) => {
          const array_values = Object.values(i);
          const got_it = array_values.filter((y) =>
            y?.toString().toLowerCase().includes(this.search.toLowerCase())
          );
          return got_it.length;
        });
        return array;
      }
    },

    get_per_page_choices() {
      let perPageChoices = [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: this.all_choice, value: -1 },
      ];
      return perPageChoices;
    },

    get_headers() {
      let headers = [
        {
          title: this.fechaRegistro,
          align: "center",
          sortable: true,
          key: "fechaRegistro",
        },
        {
          title: this.email_user,
          align: "center",
          sortable: true,
          key: "email_user",
        },
        {
          title: this.full_name,
          align: "center",
          sortable: true,
          key: "name",
          width: "150",
        },
        {
          title: this.mindset_fit,
          align: "center",
          sortable: true,
          key: "mindset_fit",
        },

        {
          title: this.dominant_individual_arq,
          align: "center",
          sortable: true,
          key: "dominant_individual_arq",
          width: "150",
        },

        {
          title: "Comparar con",
          align: "center",
          sortable: false,
          key: "parametrizations",
          width: "150",
        },

        {
          title: this.actions,
          align: "center",
          sortable: false,
          key: "actions",
        },
        {
          title: this.tags,
          align: "center",
          sortable: false,
          key: "tags",
        },
      ];
      return headers;
    },
  },

  methods: {
    openDropdownDialog(token) {
      this.$nextTick(() => {
        this.dropdownVisible[token] = true;
      });
    },
    closeDropdownDialog(token) {
      this.dropdownVisible[token] = false;
    },
    openConfirmationDialog(candidateToken, paramId) {
      this.selectedCandidateToken = candidateToken;
      this.selectedParamId = paramId;
      this.confirmationDialogVisible = true;
    },

    shouldInvertColors(item) {
      // Aquí puedes definir la condición para invertir los colores
      return item.raw.selectedParametrization === "N/D";
    },
    async selectParametrization(candidateToken, paramId) {
      try {
        await ProfileEvaluationService.postParamIdealtoCandidate(
          paramId,
          candidateToken
        );
        this.updateCandidateInList(candidateToken, paramId);

        if (paramId === null) {
          const candidate = this.candidates_to_show.find(
            (cand) => cand.token === candidateToken
          );
          if (candidate) {
            candidate.selectedParametrization = "N/D";
            candidate.mindset_fit = "N/D";
            // Forzar la reactividad actualizando el array de candidatos
            this.$store.commit(
              "updateCandidatesList",
              structuredClone(this.candidates_to_show)
            );
          }
        } else {
          // Obtener los datos actualizados de parametrización y actualizar la interfaz
          const response =
            await ProfileEvaluationService.getParametrizationData(
              candidateToken,
              "es"
            );
          const data = response.data;
          this.$store.commit("setMindsetIdealFitData", data);

          // Actualizar el mindset_fit para el candidato en la lista
          const candidate = this.candidates_to_show.find(
            (cand) => cand.token === candidateToken
          );
          if (candidate) {
            candidate.mindset_fit = data.mindsetFit;
            // Forzar la reactividad actualizando el array de candidatos
            this.$store.commit(
              "updateCandidatesList",
              structuredClone(this.candidates_to_show)
            );
          }
        }
      } catch (error) {
        console.error("Error while posting data:", error);
      }
    },
    async confirmSelection() {
      const candidateToken = this.selectedCandidateToken;
      const paramId = this.selectedParamId;

      let parameter_id = paramId;
      let tokenCandidato = candidateToken;

      try {
        await ProfileEvaluationService.postParamIdealtoCandidate(
          parameter_id,
          tokenCandidato
        );

        // Actualizar la parametrización seleccionada en el estado Vuex
        this.$store.commit("updateCandidateParametrization", {
          candidateToken,
          paramId,
        });

        if (paramId === null) {
          const candidate = this.candidates_to_show.find(
            (cand) => cand.token === candidateToken
          );
          if (candidate) {
            candidate.selectedParametrization = "N/D";
            candidate.mindset_fit = "N/D";
            // Forzar la reactividad actualizando el array de candidatos
            this.$store.commit(
              "updateCandidatesList",
              structuredClone(this.candidates_to_show)
            );
          }
        } else {
          // Obtener los datos actualizados de parametrización y actualizar la interfaz
          const response =
            await ProfileEvaluationService.getParametrizationData(
              candidateToken,
              "es"
            );
          const data = response.data;
          this.$store.commit("setMindsetIdealFitData", data);

          // Actualizar el mindset_fit para el candidato en la lista
          const candidate = this.candidates_to_show.find(
            (cand) => cand.token === candidateToken
          );
          if (candidate) {
            candidate.mindset_fit = data.mindsetFit;
            // Forzar la reactividad actualizando el array de candidatos
            this.$store.commit(
              "updateCandidatesList",
              structuredClone(this.candidates_to_show)
            );
          }
        }
      } catch (error) {
        console.error("Error while posting data:", error);
      } finally {
        this.confirmationDialogVisible = false;
      }
    },
    getParamName(paramId) {
      if (paramId === "N/D") return "Sin Parametrización asignada";
      const param = this.totalParametization.find(
        (p) => p.totalParam_id === paramId
      );
      return param ? param.totalParam_name : "Sin Parametrización asignada";
    },
    updateCandidateInList(candidateToken, paramId) {
      const candidate = this.candidates_to_show.find(
        (cand) => cand.token === candidateToken
      );
      if (candidate) {
        candidate.selectedParametrization = paramId;
      }
    },

    ...mapMutations([
      "delete_candidate_tag",
      "add_candidate_tag",
      "profiles_evaluation_view_change_step",
      "parametrization_view_change_step",
      "parametrization_view_change_step_to_desktop",
      "detailed_view_step",
      "setCultureProfileData",
      "setCultureProfilePredominance",
      "setEngagementData",
      "setMindsetIdealFitData",
      "setEthicalData",
      "setPredValuesData",
      "setFitAreasData",
      "setLeaderData",
      "SET_LANGUAGE",
      "updateCandidatesList",
      "setCandidateMindsetFit",
    ]),
    /*     handleLanguageChange(newLang) {
      this.selectedLanguage = newLang;
    }, */

    openPdfDialog(item) {
      this.currentItem = item;
      this.pdfDialogVisible = true;
    },
    confirmPdfCreation() {
      if (this.currentItem && this.$refs.candidateReport) {
        this.$refs.candidateReport.updateReportData(this.opcionesReportDialog);
        this.pdfDialogVisible = false;
        this.createPDF(this.currentItem);
      }
    },
    createPDF(candidate) {
      this.$refs.candidateReport.generateReport(
        this.user_info.tokens.tokenPE,
        candidate.token
      );
    },
    toggleLanguage(lang) {
      this.$store.commit("SET_LANGUAGE", lang);
      this.setReportTranslations(lang);
      this.language = lang;
      this.selectedFlag = lang;
    },
    setReportTranslations() {
      const reportTranslations = DictionaryForReport[this.language];
      this.TypesOfReports = reportTranslations[0];
      this.ItsPossibleToDownload = reportTranslations[56];
      this.FitReport = reportTranslations[57];
      this.EthicalReport = reportTranslations[58];
      this.LeadershipReport = reportTranslations[59];
      this.FitLeyend = reportTranslations[60];
      this.EthicalLeyend = reportTranslations[61];
      this.LeadershipLeyend = reportTranslations[62];
      this.MindsetFitReport = reportTranslations[127];
      this.MindsetFitLeyend = reportTranslations[126];
    },

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.table_title = texts[39];
      this.tags_menu = texts[71];
      this.search_placeholder = texts[81];
      this.fechaRegistro = texts[100];
      this.email_user = texts[82];
      this.full_name = texts[83];
      this.mindset_fit = texts[84];
      this.desired_org_fit = texts[85];
      this.dominant_individual_arq = texts[86];
      this.parameter_name = "PERFIL IDEAL";
      this.desired_org_culture = texts[87];
      this.tags = texts[71];
      this.actions = texts[88];

      this.item_per_page_placeholder = texts[90];
      this.total = texts[89] + ":";
      this.legend1 = texts[93];
      this.legend2 = texts[94];
      this.all_choice = texts[91];
      this.paraIDealMent = texts[108];
    },

    countCandidate() {
      //const search = this.search.toLowerCase()

      if (!this.search) {
        return this.candidates_to_show.length;
      } else {
        let array = this.candidates_to_show.filter((i) => {
          const array_values = Object.values(i);
          const got_it = array_values.filter((y) =>
            y?.toString().toLowerCase().includes(this.search.toLowerCase())
          );
          return got_it.length;
        });
        return array.length;
      }
    },

    show_text() {
      return this.user_info.countPE == 0;
    },

    searchTag(array, tag) {
      return array.indexOf(tag) != -1;
    },

    tags_to_display(array_tags, count) {
      const tags_to_display = [];
      for (let index = 0; index < count; index++) {
        tags_to_display.push(array_tags.at(index));
      }
      return tags_to_display;
    },

    tag_text_to_display(tag_text) {
      if (tag_text.length > 8) {
        return tag_text.substring(0, 8) + "...";
      } else {
        return tag_text;
      }
    },

    async candidate_details(cand) {
      try {
        await this.loadCandidateCultureProfile(cand.token);
        await this.loadCandidateEngagement(cand.token);
        await this.getCandidateReportData(cand.token);
        await this.getCandidateLeadershipData(cand.token);
        await this.getParametrizationData(cand.token);
        await this.getCandidateDataForMindsetFit(cand.token);

        this.profiles_evaluation_view_change_step(cand);
      } catch (error) {
        console.error(error);
      }
    },

    /*     createPDF(candidate) {
      this.$refs.candidateReport.generateReport(
        this.user_info.tokens.tokenPE,
        candidate.token
      );
    }, */

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async loadCandidateCultureProfile(candToken) {
      try {
        const response =
          await ProfileEvaluationService.loadCandidateCultureProfile(
            this.user_info.tokens.tokenPE,
            candToken,
            "es"
          );
        let data = response.data;

        this.setCultureProfileData(data);

        //Predominance table data seted on predominance variable
        let p = [];

        for (let index = 0; index < data.arqTITULO.length; index++) {
          var obj = {
            title: data.arqTITULO[index],
            value: data.graf2_1_1[index],
          };

          p.push(obj);
        }

        p.sort(function (a, b) {
          return b.value - a.value;
        });

        this.setCultureProfilePredominance(p);
      } catch (error) {
        console.log(error);
      }
    },

    async loadCandidateEngagement(candToken) {
      try {
        const response = await ProfileEvaluationService.loadCandidateEngagement(
          this.user_info.tokens.tokenPE,
          candToken,
          "es"
        );
        let data = response.data;

        this.setEngagementData(data);
      } catch (error) {
        console.log(error);
      }
    },
    async getParametrizationData(candToken) {
      try {
        const response = await ProfileEvaluationService.getParametrizationData(
          candToken,
          "es"
        );
        let data = response.data;

        // Actualizar el estado Vuex con los datos obtenidos
        this.$store.commit("setMindsetIdealFitData", data);
      } catch (error) {
        console.log(error);
      }
    },
    async getCandidateReportData(candToken) {
      try {
        const response = await ProfileEvaluationService.getCandidateReportData(
          this.user_info.tokens.tokenPE,
          candToken,
          "es"
        );
        let data = response.data;

        this.setEthicalData(data);
        this.setFitAreasData(data);

        this.setPredValuesData(data);
      } catch (error) {
        console.log(error);
      }
    },

    async getCandidateLeadershipData(candidateToken) {
      try {
        const response =
          await ProfileEvaluationService.getCandidateLeadershipData(
            candidateToken,
            "es"
          );
        let data = response.data;

        this.setLeaderData(data);
      } catch (error) {
        console.log(error);
      }
    },

    async getCandidateDataForMindsetFit(candidateToken) {
      try {
        const response =
          await ProfileEvaluationService.getCandidateDataForMindsetFit(
            candidateToken,
            this.user_info.tokens.tokenPE
          );
       
        this.setCandidateMindsetFit(response.data);
      } catch (error) {
        console.log(error);
      }
    },

    //Add Tag

    async setTagToCandidate(cand, tag) {
      let formData = new FormData();
      formData.append("candidato_id", cand.token);
      formData.append("tag_id", tag.id);

      try {
        const response = await TagsService.setTagToCandidate(formData);
        let data = response.data;

        if (data.code == 200) {
          this.add_candidate_tag([cand.index, tag, data.message.id]);
        }
      } catch (error) {
        console.log(error);
      }
    },

    //Remove Tag from Candidate
    async deleteCandidateTag(cand, tag) {
      let formData = new FormData();
      formData.append("id", tag.id);

      try {
        const response = await TagsService.deleteCandidateTag(formData);
        let data = response.data;

        if (data.code == 200) {
          this.delete_candidate_tag([cand, tag]);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  /*   mounted() {
    this.fetchParametrizationList();
  }, */
  watch: {
    language(newLang) {
      this.setReportTranslations(newLang);
    },
  },

  created() {
    this.set_texts();
    /*     this.fetchParametrizationList(); */
  },
};
</script>

<style>
.ment-inf {
  color: #01c3c0;
}
.v-data-table .v-table__wrapper > table > thead > tr > td,
.v-data-table .v-table__wrapper > table > thead > tr th,
.v-data-table .v-table__wrapper > table tbody > tr > td,
.v-data-table .v-table__wrapper > table tbody > tr th {
  background-color: transparent !important;
  color: #585858;
  border-bottom: 1px solid #00c3c0 !important;
}

.v-data-table.headers .v-table__wrapper > table > thead > tr th,
.v-data-table.headers .v-table__wrapper > table tbody > tr th {
  color: #34495e !important;
  background-color: #fbfbfb !important;
}

.v-data-table .v-table__wrapper > table tbody > tr:hover {
  background: #f4ffff;
}

.v-data-table .v-table__wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
}

.v-data-table .v-table__wrapper::-webkit-scrollbar:vertical {
  width: 10px;
}

.v-data-table .v-table__wrapper::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.v-data-table .v-table__wrapper::-webkit-scrollbar:horizontal {
  height: 10px;
}

.v-data-table .v-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #00c3c0;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.v-data-table .v-table__wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}

.select_label_chip {
  background-color: #00c3c0;
  color: white;
}

.chip {
  background-color: #c2c8cf;
}

.table_actions_icons {
  color: #34495e;
}

.filter_icon {
  background-color: #00c3c0;
}

.search_tf {
  font-style: italic;
}

.tags_to_add {
  background-color: #bdf0ee !important;
}

.list_tag_menu {
  border-bottom: 1px thin black;
}

.select {
  background-color: #bdf0ee !important;
}
.pdf-button {
  background-color: #34495e !important;
  color: white !important;
}

.close-and-flags {
  display: flex;

  position: absolute;
  top: 10px;
  right: 10px;
}

.close-btn {
  margin-right: 10px; /* Espacio entre el botón de cierre y las banderas */
}

.flag-switcher {
  display: flex;
}

.flag-icon {
  width: 23px;
  height: auto;
}

.rotate-flag {
  animation: rotateFlag 0.6s ease-in-out forwards;
}

@keyframes rotateFlag {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.flag-button2 {
  margin-right: 40px;
}
.flag-button {
  margin-right: 20px;
}
.btn {
  background-color: #00c3c0;
  color: white !important;
  font-size: 12px;
}
.btnGest {
  background-color: #34495e;
  color: white !important;
  font-size: 12px;
}
@media (max-width: 1800px) {
  .btnGest {
    background-color: #34495e;
    color: white !important;
    font-size: 9px;
  }
}
@media (max-width: 1600px) {
  .btnGest {
    background-color: #34495e;
    color: white !important;
    font-size: 8px;
  }
}
.smallerTButton {
  font-size: 10px !important;
}
.smallerTButtonPlus {
  font-size: 10px !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #34495e !important;
  color: rgb(255, 255, 255);
  padding: 10px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  margin: 2px;
  min-width: 183px;
  max-height: 190px;
  justify-content: space-between;
  align-items: center;
}
.inverted {
  background-color: #00c3c0 !important;
  color: #ffffff;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  max-height: 190px;
  overflow-y: auto;
  min-width: 253px;
  max-height: 190px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa; /* or add it to the track */
}
.dropdown-content::-webkit-scrollbar-thumb {
  background: #000;
}

.dropdown-content a {
  color: black;
  padding: 12px 14px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid #000000;
  min-width: 160px;
  max-height: 190px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.dropdown-content a:hover {
  background-color: #888888;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #ddd;
}
.dropdown-content .delete-param {
  font-size: 22px;
  height: 35px;
  padding: 0px 4px;
}
.close-icon2 {
  color: white !important;
  cursor: pointer;
}
.menu-icon {
  cursor: pointer;
}
.centerSpan {
  margin: 0 auto;
}
.item-list-param {
  border-bottom: solid 1px #34495e45 !important;
}
.scrollable-list {
  max-height: 300px; /* Ajusta este valor según sea necesario */
  overflow-y: scroll;
}

.scrollable-list::-webkit-scrollbar {
  width: 10px; /* Ancho del scroll */
  height: 10px; /* Altura del scroll */
  background-color: #f1f1f1; /* Color de fondo del scroll */
}

.scrollable-list::-webkit-scrollbar-thumb {
  background-color: #00c3c0; /* Color del thumb del scroll */
  border-radius: 10px; /* Radio de borde del thumb del scroll */
}

.scrollable-list::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color del track del scroll */
  border-radius: 10px; /* Radio de borde del track del scroll */
}
</style>
